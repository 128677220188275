import React from 'react'
import { Routes, Route } from 'react-router-dom'

// Miscellaneous
import paths from '../../utils/paths'

// Components
import Layout from '../Layout'
import Loader from '../Loader'
import ProtectedReconciliationRoute from '../ProtectedReconciliationRoute'
import ProtectedRoute from '../ProtectedRoute'
import StepRedirector from '../StepRedirector'

// Pages
const AccountLeasedAssetListPage = React.lazy(() => import('../../pages/Account/AccountLeasedAssetList'))
const AccountLayout = React.lazy(() => import('../../pages/Account/AccountLayout'))
const AccountNotesPage = React.lazy(() => import('../../pages/Account/AccountNotes/AccountNotesLoader'))
const AccountOverviewPage = React.lazy(() => import('../../pages/Account/AccountOverview'))
const AccountPaymentsPage = React.lazy(() => import('../../pages/Account/AccountPayments'))
const AccountDelinquencyPage = React.lazy(() => import('../../pages/Account/AccountDelinquency'))
const AccountPropertyAssetListPage = React.lazy(() => import('../../pages/Account/AccountPropertyAssetList'))
const AccountUsersPage = React.lazy(() => import('../../pages/Account/AccountUsers'))
const AccountAssociatedAccountsPage = React.lazy(() => import('../../pages/Account/AccountAssociatedAccounts'))
const AccountTypePage = React.lazy(() => import('../../pages/AccountTypes'))
const AccountsPage = React.lazy(() => import('../../pages/Accounts'))
const AdminUsersPage = React.lazy(() => import('../../pages/AdminUsers/AdminUsers'))
const AppraiserRegionsPage = React.lazy(() => import('../../pages/BusinessAppraiserRegions'))
const BasicCostPage = React.lazy(() => import('../../pages/BasicCost'))
const CountySettingsPage = React.lazy(() => import('../../pages/CountySettings'))
const ForgotPasswordPage = React.lazy(() => import('../../pages/ForgotPassword'))
const HomePage = React.lazy(() => import('../../pages/Home'))
const LeaseMasterAccountsPage = React.lazy(() => import('../../pages/LeaseMaster/LessorAccounts'))
const LeaseMasterAreasPage = React.lazy(
  () => import('../../pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterAreas'),
)
const LeaseMasterAccountNotesPage = React.lazy(
  () => import('../../pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterAccountNotes'),
)
const LeaseMasterLayout = React.lazy(
  () => import('../../pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterLayout'),
)
const LeaseMasterOverviewPage = React.lazy(
  () => import('../../pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterOverview'),
)
const LeaseMasterPaymentPostingPage = React.lazy(() => import('../../pages/LeaseMaster/LessorPaymentPosting'))
const LeaseMasterPaymentReconciliationPage = React.lazy(
  () => import('../../pages/LeaseMaster/LessorPaymentReconciliation'),
)
const Login = React.lazy(() => import('../../pages/Login'))
const PageNotFound = React.lazy(() => import('../../pages/PageNotFound'))
const PasswordReset = React.lazy(() => import('../../pages/PasswordReset'))
const ProfilePage = React.lazy(() => import('../../pages/Profile/Profile'))
const PropertyClassificationPage = React.lazy(() => import('../../pages/PropertyClassification'))
const ResetPasswordRequestedPage = React.lazy(() => import('../../pages/ForgotPassword/ResetPasswordRequested'))
const RolesPage = React.lazy(() => import('../../pages/Roles/Roles'))
const SicCodeMastersPage = React.lazy(() => import('../../pages/SicCodeMasters'))
const SicCodePage = React.lazy(() => import('../../pages/SicCodes'))
const SicSiloCodePage = React.lazy(() => import('../../pages/SicCodes/SicSiloCodesLoader'))
const SubSiloCodePage = React.lazy(() => import('../../pages/SicCodes/SubSiloCodesLoader'))
const StatusReportsPage = React.lazy(() => import('../../pages/ReportModule/StatusReports'))
const AccountingReportsPage = React.lazy(() => import('../../pages/ReportModule/AccountingReports'))
const LetterAndFormReportsPage = React.lazy(() => import('../../pages/ReportModule/LetterAndForms'))
const NotificationFilesReportsPage = React.lazy(() => import('../../pages/ReportModule/NotificationFiles'))
const PaymentSearchPage = React.lazy(() => import('../../pages/Payments/PaymentSearch'))
const PaymentSummaryPage = React.lazy(() => import('../../pages/Payments/PaymentSummary'))
const PaymentTotalsPage = React.lazy(() => import('../../pages/Payments/PaymentTotals'))
const DelinquentSummaryPage = React.lazy(() => import('../../pages/Payments/DelinquentSummary'))
const ReturnedPaymentPage = React.lazy(() => import('../../pages/Payments/ReturnedPayment'))
const DelinquentReturnedPaymentPage = React.lazy(() => import('../../pages/Payments/DelinquentReturnedPayment'))
const ReconciliationLayout = React.lazy(() => import('../../pages/Reconciliation/Layout'))
const ReconciliationAccountPage = React.lazy(() => import('../../pages/Reconciliation/Account'))
const ReconciliationContactsPage = React.lazy(() => import('../../pages/Reconciliation/Contacts'))
const ReconciliationPropertyAssetsPage = React.lazy(() => import('../../pages/Reconciliation/PropertyAssets'))
const ReconciliationLessorsPage = React.lazy(() => import('../../pages/Reconciliation/Lessors'))
const ReconciliationSummaryPage = React.lazy(() => import('../../pages/Reconciliation/FilingSummary'))
const DelinquentAdjustmentPage = React.lazy(() => import('../../pages/Delinquency/DelinquentAdjustment'))
const VoidDelinquentPaymentPage = React.lazy(() => import('../../pages/Payments/VoidDelinquentPayment'))

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path={paths.login}
          element={
            <React.Suspense fallback={<Loader loading />}>
              <Login />
            </React.Suspense>
          }
        />
        <Route
          path={paths.forgotPassword}
          element={
            <React.Suspense fallback={<Loader loading />}>
              <ForgotPasswordPage />
            </React.Suspense>
          }
        />
        <Route
          path={paths.resetPasswordSent}
          element={
            <React.Suspense fallback={<Loader loading />}>
              <ResetPasswordRequestedPage />
            </React.Suspense>
          }
        />
        <Route
          path={paths.passwordReset}
          element={
            <React.Suspense fallback={<Loader loading />}>
              <PasswordReset />
            </React.Suspense>
          }
        />
        <Route element={<ProtectedRoute />}>
          <Route
            path={paths.home}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <HomePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.leaseMasterAccountsPage}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <LeaseMasterAccountsPage />
              </React.Suspense>
            }
          />
          <Route element={<LeaseMasterLayout />}>
            <Route
              path={paths.leaseMasterOverviewPage}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <LeaseMasterOverviewPage />
                </React.Suspense>
              }
            />

            <Route
              path={paths.leaseMasterAreasPage}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <LeaseMasterAreasPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.leaseMasterNotesPage}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <LeaseMasterAccountNotesPage />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path={paths.leaseMasterPaymentPostingPage}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <LeaseMasterPaymentPostingPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.leaseMasterPaymentReconciliationPage}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <LeaseMasterPaymentReconciliationPage />
              </React.Suspense>
            }
          />

          <Route
            path={paths.basicCost}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <BasicCostPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.accounts}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AccountsPage />
              </React.Suspense>
            }
          />
          <Route element={<AccountLayout />}>
            <Route
              path={paths.accountOverview}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountOverviewPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountAssociatedAccounts}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountAssociatedAccountsPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountPropertyAssetList}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountPropertyAssetListPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountLeasedAssetList}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountLeasedAssetListPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountPayments}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountPaymentsPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountDelinquency}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountDelinquencyPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountNotes}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountNotesPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountUsers}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountUsersPage />
                </React.Suspense>
              }
            />
          </Route>

          <Route element={<ProtectedReconciliationRoute />}>
            <Route element={<ReconciliationLayout />}>
              <Route
                path={paths.reconciliation}
                element={
                  <React.Suspense fallback={<Loader loading />}>
                    <StepRedirector />
                  </React.Suspense>
                }
              />
              <Route
                path={paths.reconciliationAccountInformation}
                element={
                  <React.Suspense fallback={<Loader loading />}>
                    <ReconciliationAccountPage />
                  </React.Suspense>
                }
              />
              <Route
                path={paths.reconciliationContacts}
                element={
                  <React.Suspense fallback={<Loader loading />}>
                    <ReconciliationContactsPage />
                  </React.Suspense>
                }
              />
              <Route
                path={paths.reconciliationPropertyAssets}
                element={
                  <React.Suspense fallback={<Loader loading />}>
                    <ReconciliationPropertyAssetsPage />
                  </React.Suspense>
                }
              />
              <Route
                path={paths.reconciliationLeasedAssets}
                element={
                  <React.Suspense fallback={<Loader loading />}>
                    <ReconciliationLessorsPage />
                  </React.Suspense>
                }
              />
              <Route
                path={paths.reconciliationSummary}
                element={
                  <React.Suspense fallback={<Loader loading />}>
                    <ReconciliationSummaryPage />
                  </React.Suspense>
                }
              />
            </Route>
          </Route>

          <Route
            path={paths.countySettings}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <CountySettingsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.propertyClassification}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <PropertyClassificationPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.sicCodeMasters}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <SicCodeMastersPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.sicCodes}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <SicCodePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.sicSiloCodes}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <SicSiloCodePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.subSiloCodes}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <SubSiloCodePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.statusReport}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <StatusReportsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.accountingReports}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AccountingReportsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.letterAndFormReports}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <LetterAndFormReportsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.notificationFileReports}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <NotificationFilesReportsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.appraiserRegions}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AppraiserRegionsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.profile}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <ProfilePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.accountType}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AccountTypePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.adminUsers}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AdminUsersPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.roles}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <RolesPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.paymentSearch}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <PaymentSearchPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.paymentSummary}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <PaymentSummaryPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.paymentTotals}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <PaymentTotalsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.delinquentSummary}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <DelinquentSummaryPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.delinquentAdjustment}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <DelinquentAdjustmentPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.paymentReturnedPayment}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <ReturnedPaymentPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.paymentDelinquentYear}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <DelinquentReturnedPaymentPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.paymentReturnedPayment}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <ReturnedPaymentPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.paymentDelinquentYear}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <DelinquentReturnedPaymentPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.voidDelinquentPayment}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <VoidDelinquentPaymentPage />
              </React.Suspense>
            }
          />
        </Route>
      </Route>

      <Route element={<Layout isPageNotFound />}>
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader loading />}>
              <PageNotFound />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
